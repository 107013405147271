:global {
  .waterfallBox {
    margin: 0 6px 0 6px;
  }
  .waterfall-bottom-container {
    //padding: 6px;
    font-size: 13px;
  }
  .waterfall-container {
    margin-top: 6px;
    background: rgb(242, 246, 248);
    -moz-column-count:2; /* Firefox */
    -webkit-column-count:2; /* Safari 和 Chrome */
    column-count:2;
    //-moz-column-gap: 16px;
    //-webkit-column-gap: 16px;
    column-gap: 1px;
    img {
      border-radius: 6px 6px 0 0;
      max-height: 400px !important;
      object-fit: cover;
      width: 100%;
    }
    .item {
      background: #fff;
      break-inside: avoid;
      //padding-bottom: 8px;
      margin: 9px 6px 9px 6px;
      border-radius: 6px;
      .waterfall-render {
        padding: 6px;
        .desc {
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          font-weight: bold;
          margin: 6px 0;
        }
        .bot {
          display: flex;
          justify-content: space-between;
          .city, .rate {
            display: flex;
            align-items: center;
          }
          .rate {
            svg {
              width: 25px;
              height: 25px;
            }
          }
        }
      }
    }
  }
}
