:global {
}

.myHeader {
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 2;
}

.search {
  padding: 10px 6px 6px 6px;
}
.myTabs {
  display: flex;
  overflow: scroll;
  padding: 6px 0 6px 6px;
  span {
    font-size: 14px;
    color: #6a6a6a;
    padding-right: 18px;
    white-space: nowrap;
  }
  :global {
    span.active {
      font-weight: bold;
      color: #000;
    }
  }
}
