.NoData {
  svg {
    width: 40px;
    height: 40px;
  }
  display: flex;
  align-items: center;
  >div {
    padding: 20px 0;
    margin: 0 auto;
    text-align: center;
    span {
      color: #6a6a6a;
      display: block;
    }
  }
}
