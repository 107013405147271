.content {
  height: 100%;
  min-height: 150px;
  //height: 120px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  user-select: none;
  img, video {
    width: 100%;
    max-height: 60vh;
    object-fit: contain;
  }
}

.detailInfo {
  padding: 12px 8px;
  .markTitle {
    font-size: 14px;
  }
}

.markTable {
  .listDiv {
    display: table;
    padding: 6px 0;
    .spanLabel {
      width: 80px;
      font-size: 13px;
      color: #000;
    }
    .tableDiv {
      :global {
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
    .spanLabel, .tableDiv {
      display: table-cell;
      color: #333333;
    }
    .tableDiv {
      display: flex;
      align-items: center;
    }
  }
}

.spanTags {
  padding: 1px 4px;
  font-size: 12px;
  display: inline-block;
  border: 1px orangered rgba(255, 64, 0, 0.86);
  color: #fff;
  background: rgba(255, 64, 0, 0.86);
  border-radius: 3px;
  margin-right: 4px;
}
:global {
  .adm-swiper-item, .adm-swiper-slide {
    max-height: 60vh;
  }
}
