:global {
  .index-item {
    overflow: hidden;
    img {
      max-height: 200px !important;
      object-fit: cover;
      width: 100%;
    }
    .desc {
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      font-weight: bold;
      margin: 6px 0;
    }
    .time {
      font-size: 12px;
      color: #abb2bf;
    }
    .bot {
      display: flex;
      justify-content: space-between;
      .city, .rate {
        display: flex;
        align-items: center;
        font-size: 12px;
      }
      .rate {
        svg {
          width: 24px;
          height: 25px;
          margin-right: -8px;
        }
      }
    }
  }
}
