:global {
  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    width: auto;
  }
  .my-masonry-grid_column {
    //padding: 8px; /* gutter size */
    background-clip: padding-box;
    padding: 6px;
    box-sizing: border-box;
  }
  /* Style your items */
  .my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    padding: 6px;
    background: #fff;
    margin-bottom: 8px;
    overflow: hidden;
    //color: white;
    word-wrap: break-word;
    border-radius: 8px;
  }
}
